import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import { CampaignCurrency } from "../enums/campaign.enums";
import { SerializableObject } from "./serializable-object";

export class BrandPartnerBudget extends SerializableObject {
  id!: number;
  budget!: number;
  fromDate!: Date;
  toDate!: Date;
  currency!: CampaignCurrency;
  budgetAssignedToCampaigns!: number;
  hasPartnerAssignedBudgetToCampaigns!: boolean;

  static create(): BrandPartnerBudget {
    const brandPartnerBudget = new BrandPartnerBudget();
    brandPartnerBudget.budget = 0;
    brandPartnerBudget.currency = CampaignCurrency.EUR;
    brandPartnerBudget.fromDate = new Date();
    brandPartnerBudget.toDate = dayjs().add(1, "month").toDate();
    brandPartnerBudget.hasPartnerAssignedBudgetToCampaigns = false;
    return brandPartnerBudget;
  }

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "fromDate":
        return dayjs(propertyValue).utc().toDate();
      case "toDate":
        return dayjs(propertyValue).utc().toDate();
      default:
        return propertyValue;
    }
  }
}
