import { BrandPartnerBudget } from "./brandPartnerBudget";
import { SerializableObject } from "./serializable-object";

export class BrandPartner extends SerializableObject {
  id!: number;
  partnerId!: number;
  partnerCompanyName!: string;
  budgetAllocated!: number;
  budgetAssigned!: number;
  budgetSpent!: number;
  budgets!: BrandPartnerBudget[];

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      case "budgets":
        return propertyValue.map((row: any) => new BrandPartnerBudget(row));
      default:
        return propertyValue;
    }
  }
}
