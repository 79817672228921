import { CreateBrandPartnerBudgetFailedResponse } from "./create-brand-partner-budget-failed-response";

export class CreateBrandPartnerBudgetsResponse {
  public successful = [];
  public failed: CreateBrandPartnerBudgetFailedResponse[] = [];
  constructor(
    successful = [],
    failed: CreateBrandPartnerBudgetFailedResponse[] = [],
  ) {
    this.successful = successful;
    this.failed = failed;
  }
}
