import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BrandPartner } from "../../../../shared/models/brandPartner";
import { BrandPartnerBudget } from "../../../../shared/models/brandPartnerBudget";
import { HttpClient } from "../../../../shared/services/http-client";
import { CreateBrandPartnerBudgetsData } from "../../../../shared/services/parameters/create-brand-partner-budgets-data";
import { CreateBrandPartnerBudgetFailedResponse } from "../../../../shared/services/responses/create-brand-partner-budget-failed-response";
import { CreateBrandPartnerBudgetsResponse } from "../../../../shared/services/responses/create-brand-partner-budgets-response";

import { BrandService } from "../../../../shared/services/api/brand.service";

@Injectable({ providedIn: "root" })
export class BrandPartnerBudgetService {
  constructor(
    private readonly http: HttpClient,
    private readonly brandService: BrandService,
  ) {}

  private getCurrentBrandId(): number {
    return this.brandService.currentBrandId;
  }

  validateBrandPartnerBudgets(
    createBrandPartnerBudgetsData: CreateBrandPartnerBudgetsData,
  ): Observable<CreateBrandPartnerBudgetsResponse> {
    const url = `brand/${this.getCurrentBrandId()}/partner_budget_validation`;

    return this.http.post(url, createBrandPartnerBudgetsData).pipe(
      map(({ body }) => {
        const failed = body.failed.map(
          (d: any) =>
            new CreateBrandPartnerBudgetFailedResponse(
              d.failedKey,
              d.brandPartnerCompanyId,
              d.brandPartnerCompanyName,
            ),
        );
        return new CreateBrandPartnerBudgetsResponse([], failed);
      }),
    );
  }

  createBrandPartnerBudgets(
    createBrandPartnerBudgetsData: CreateBrandPartnerBudgetsData,
  ): Observable<CreateBrandPartnerBudgetsResponse> {
    const url = `brand/` + this.getCurrentBrandId() + "/partner_budget";
    return this.http.post(url, createBrandPartnerBudgetsData).pipe(
      map(({ body }) => {
        const failed = body.failed.map(
          (d: any) =>
            new CreateBrandPartnerBudgetFailedResponse(
              d.failedKey,
              d.brandPartnerCompanyId,
              d.brandPartnerCompanyName,
            ),
        );
        return new CreateBrandPartnerBudgetsResponse(body.successful, failed);
      }),
    );
  }

  updateBrandPartnerBudget(
    brandPartnerId: number,
    brandPartnerBudget: BrandPartnerBudget,
  ): Observable<BrandPartner> {
    const url =
      `brand/` +
      this.getCurrentBrandId() +
      "/partner/" +
      brandPartnerId +
      "/budget/" +
      brandPartnerBudget.id;
    return this.http
      .put(url, brandPartnerBudget)
      .pipe(map(({ body }) => new BrandPartner(body)));
  }

  deleteBrandPartnerBudget(
    brandPartnerId: number,
    brandPartnerBudget: BrandPartnerBudget,
  ): Observable<void> {
    const url =
      `brand/` +
      this.getCurrentBrandId() +
      "/partner/" +
      brandPartnerId +
      "/budget/" +
      brandPartnerBudget.id;

    return this.http.del(url).pipe(map(() => {}));
  }
}
