export enum CreateBrandPartnerBudgetFailedErrorKey {
  OverlappingTimeFrame = "BRAND_PARTNER_BUDGET_OVERLAPPING_TIME_FRAME",
  BudgetLessThanAllocated = "BRAND_PARTNER_BUDGET_LESS_THAN_ALLOCATED",
}

export class CreateBrandPartnerBudgetFailedResponse {
  constructor(
    public readonly failedKey: CreateBrandPartnerBudgetFailedErrorKey,
    public readonly brandPartnerCompanyId: number,
    public readonly brandPartnerCompanyName: string,
  ) {}
}
